var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.datas, function (item, i) {
    return _c('b-card', {
      key: i
    }, [_c('router-link', {
      attrs: {
        "to": "/kendaraan-barang/detail/".concat(item.id)
      }
    }, [item.kendaraan ? _c('b-badge', {
      staticClass: "mb-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "TruckIcon"
      }
    }), _vm._v(_vm._s(item.driver.nama_lengkap))], 1) : _vm._e(), item.kendaraan ? _c('b-badge', {
      staticClass: "ml-1 mb-1",
      attrs: {
        "variant": "light-info"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "UserIcon"
      }
    }), _vm._v(_vm._s(item.karyawan.nama_lengkap))], 1) : _vm._e(), item.kendaraan ? _c('b-badge', {
      staticClass: "ml-1 mb-1",
      attrs: {
        "variant": "light-danger"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "MapPinIcon"
      }
    }), _vm._v(_vm._s(item.teritori.nama_teritori) + " - " + _vm._s(item.alamat))], 1) : _vm._e(), _c('h4', {
      staticClass: "mb-2"
    }, [_vm._v(_vm._s(_vm.getKendaraanName(item)))]), _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$router.push("/kendaraan-barang/detail/".concat(item.id));
        }
      }
    }, [_vm._v(" Lihat Stok ")])], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }